import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import aboutThumb from "../images/artwork-about-us.svg";
import { TitleH1 } from "../components/common";
import SEO from "../components/SEO";

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% minmax(400px, 600px);
  align-items: center;
  justify-content: space-evenly;
  grid-gap: 0 2rem;
  padding: 0 30px 3rem;
  @media (max-width: 1024px) {
    grid-template-columns: 1.2fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const TitleH2 = styled.p`
  font-size: 20px;
  letter-spacing: 0px;
  margin: 2rem 0 1rem;
  font-weight: 500;
  color: #3d4d69;
  @media (max-width: 575px) {
    padding: 0;
  }
`;
const TitleH3 = styled.h3`
  font-size: 28px;
  letter-spacing: 0.42px;
  margin: 3rem 0 2.5rem;
  @media (max-width: 575px) {
    font-size: 26px;
    margin: 2rem 0;
    padding: 0;
  }
`;
const TitleContent = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1.4rem;
  color: #3d4d69;
`;
const ContactUsSection = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-items: center;
  padding: 0 20px;
  margin-bottom: 3.5rem;
`;
const CommonColorButton = styled.div`
  color: ${(props) => (props.color ? props.color : `white`)};
  font-size: ${(props) => (props.size ? props.size : `16px`)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  border-radius: ${(props) => (props.radius ? props.radius : `6px`)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : `#20BEAD`)};
  padding: ${(props) => (props.padding ? props.padding : `14px 30px`)};
  cursor: ${(props) => (props.cursor ? props.cursor : `pointer`)};
  white-space: nowrap;
  text-align: center;
  transition: ${(props) => props.effect === "effect" && `opacity 0.2s`};
  &:hover {
    background-color: ${(props) => props.hoverBg && props.hoverBg};
  }
`;
const StyledImg = styled.img`
  margin-top: 40px;
  @media (max-width: 768px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;
const StyledLink = styled(Link)`
  color: #06c;
  text-decoration: none;
`;

const About = ({ location }) => {
  return (
    <>
      <SEO
        title="About Us"
        description=""
        pathname={location.pathname && location.pathname}
        sofwareApp
      />
      <Container>
        <div>
          <TitleH1>Our Mission</TitleH1>
          <TitleH2>#1 Online Receipt Maker</TitleH2>
          <TitleH3>About Receiptmakerly</TitleH3>
          <TitleContent>
            With the best onnline receipt generator – ReceiptMaker, you can
            create customize and profiessional receipts in just a few minutes.
            From grocery store receipts to taxi or even for restaurants! There
            are many collection templates avaiable, so where’s one perfect fit
            for your business needs. Receiptmaker offers you a wide range of
            collections of customizable receipts templates like{" "}
            <a
              href="https://receiptmakerly.com/grocery-store-receipts/"
              target="_blank"
            >
              Grocery Receipts
            </a>{" "}
            Generators, Simple Generic Receipts, Sales Receipts{" "}
            <a
              href="https://receiptmakerly.com/walgreens-style-receipts-generator/"
              target="_blank"
            >
              Pharmacy Receipts like Walgreens
            </a>{" "}
            and many more.
          </TitleContent>
          <TitleContent>
            You can include personal infomation, logos, text, and other
            essential information in professional templates and create
            customized receipts in a matter of minutes. You can now have
            professional-looking receipts that match your business’s look,
            style, and components by choosing from a selection for design.
          </TitleContent>
        </div>
        <StyledImg src={aboutThumb} alt="about us thumbnail" width="100%" />
      </Container>
      <ContactUsSection>
        <TitleH2>Contact Us</TitleH2>
        <TitleContent>
          We are just a message away to get in touch with you.
        </TitleContent>
        <CommonColorButton>
          <StyledLink to="/contact" style={{ color: `#FFF` }}>
            Contact Us
          </StyledLink>
        </CommonColorButton>
      </ContactUsSection>
    </>
  );
};

export default About;
